const styles = {
  card: {
    flex: 1,
  },
  image: {
    objectFit: 'contain',
    maxHeight: '150px',
    overflow: 'hidden',
    width: '100%',
  },
  detailsProps: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  },
  textDetailsProps: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'space-around',
  },
  gridContainer: {
    height: '60vh',
  },
  grid: {
    '& .MuiDataGrid-virtualScroller': {
      scrollbarWidth: 'thin',
      scrollbarColor: '#888 #f0f0f0',
    },
    '& .MuiDataGrid-virtualScroller::-webkit-scrollbar': {
      width: '8px',
      height: '8px',
    },
    '& .MuiDataGrid-virtualScroller::-webkit-scrollbar-track': {
      background: '#f0f0f0',
      borderRadius: '4px',
    },
    '& .MuiDataGrid-virtualScroller::-webkit-scrollbar-thumb': {
      background: '#888',
      borderRadius: '4px',
    },
    '& .MuiDataGrid-virtualScroller::-webkit-scrollbar-thumb:hover': {
      background: '#555',
    },
  },
};

export default styles;
