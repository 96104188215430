import React, { useContext } from 'react';
import { useForm } from 'react-hook-form';
import { useNavigate } from 'react-router-dom';
import { toastWarning } from 'utils/toast';
import { encryptURL } from 'utils/functions';
import { OrçamentosContext } from 'contexts/OrçamentosContext';
import { DocumentosContext } from 'contexts/DocumentosContext';
import { RelatoriosContext } from 'contexts/RelatoriosContext';
import { DropsContext } from 'contexts/DropsContext';
import { GridContext } from 'contexts/GridContext';
import { AuthContext } from 'contexts/AuthContext';
import { useModal } from 'components/Modals';
import ResumoModal from 'components/Modals/ResumoModal';
import Container from 'components/Container';
import Header from 'components/Header';
import Card from 'components/Card';
import Grid from 'components/Grid';
import Filter from './filter';
import mock from './mock';

const Orçamentos = () => {
  const rotina = 'ServicosOrçamentos';
  const titulo = 'Orçamentos';
  const navigate = useNavigate();
  const { openModal } = useModal();
  const { user } = useContext(AuthContext);
  const { drops } = useContext(DropsContext);
  const { postDocumento, postLoading: docLoad } = useContext(DocumentosContext);
  const { exportPdf, exportXls } = useContext(GridContext);
  const { getURLRelatorio } = useContext(RelatoriosContext);
  const { orçamentos, getLoading, getOrçamentos, postServico, postLoading } =
    useContext(OrçamentosContext);
  const { control, getValues, reset } = useForm({
    defaultValues: orçamentos?.filter,
  });
  const loading = getLoading || postLoading || docLoad;

  const loadGrid = (props) => {
    if (props?.replace) {
      reset();
    }
    getOrçamentos({ ...props, rotina, filter: getValues() });
  };

  const changeStatus = ({ documento_id, status_id, motivo }) => {
    const data = { Registros: [{ documento_id, status_id, motivo }] };
    postDocumento({
      data,
      cb: () => {
        loadGrid();
        closeDialog();
      },
    });
  };

  const options = [
    {
      name: 'Adicionar Orçamento',
      icon: 'add',
      show: !Boolean(user?.permissoes?.block_criar_orcamento),
      action: () => navigate('/app/Orcamentos/Gerar'),
    },
    {
      name: 'Exportar PDF',
      icon: 'file_download',
      action: () =>
        exportPdf({ rotina, filter: getValues(), order: orçamentos?.order }),
    },
    {
      name: 'Exportar XLS',
      icon: 'file_download',
      action: () =>
        exportXls({ rotina, filter: getValues(), order: orçamentos.order }),
    },
    {
      name: 'Resumo',
      icon: 'summarize',
      action: () =>
        openModal(
          <ResumoModal
            colunas={orçamentos?.colunas}
            onSubmit={(group) =>
              navigate('/app/Resumo', {
                state: { rotina, group, filter: getValues() },
              })
            }
          />
        ),
    },
    {
      name: 'Gerar Ordem de Serviço',
      icon: 'post_add',
      show: !Boolean(user?.permissoes?.block_gerar_servico),
      action: (selected) => {
        if (!Boolean(selected?.length)) {
          return toastWarning('Selecione ao menos um item');
        }
        if (selected?.some((s) => s?.status !== 'PENDENTE')) {
          return toastWarning('Selecione apenas orçamentos pendentes');
        }
        const data = { documentos: selected?.map((s) => s?.id) };
        postServico({ data, cb: loadGrid });
      },
    },
  ];
  const rowOptions = [
    {
      name: 'Em execução',
      icon: 'schedule',
      color: 'primary.main',
      show: ({ row }) => Boolean(row?.executantes),
    },
    {
      name: 'Detalhes',
      icon: 'visibility',
      action: ({ row }) => navigate(`/app/Documento/${row?.id}`),
    },
    {
      name: 'Direcionamento Técnico',
      icon: 'build',
      menu: true,
      show: ({ row }) =>
        !Boolean(user?.permissoes?.block_direcionamento_tecnico) &&
        row?.status === 'PENDENTE',
      action: ({ row }) =>
        navigate(`/app/Servicos/Direcionamento/${encryptURL(row)}`),
    },
    {
      name: 'Encaminhar Orçamento',
      icon: 'request_quote',
      menu: true,
      show: ({ row }) =>
        !Boolean(user?.permissoes?.block_encaminhar_orcamento) &&
        [1, 17, 25, 45]?.includes(row?.status_id),
      action: ({ row }) =>
        user?.nivel === 'TÉCNICO'
          ? changeStatus({ documento_id: row?.id, status_id: 25 })
          : getURLRelatorio({
              data: { codigo: 'OSORC00001', documento_id: row?.id },
              documento: row,
              cb: loadGrid,
            }),
    },
    {
      name: 'Imprimir Orçamento',
      icon: 'print',
      menu: true,
      show: ({ row }) =>
        !Boolean(user?.permissoes?.block_encaminhar_orcamento) &&
        row?.status === 'CONCLUIDO',
      action: ({ row }) =>
        getURLRelatorio({
          data: { codigo: 'OSORC00001', documento_id: row?.id },
          documento: row,
          cb: loadGrid,
        }),
    },
    {
      name: 'Reprovar Orçamento',
      icon: 'thumb_down',
      menu: true,
      show: ({ row }) =>
        !Boolean(user?.permissoes?.block_reprova_orcamento) &&
        [17]?.includes(row?.status_id),
      action: ({ row }) =>
        changeStatus({ documento_id: row?.id, status_id: 19 }),
    },
    {
      name: 'Descartar Equipamento',
      icon: 'delete',
      menu: true,
      show: ({ row }) =>
        Boolean(row?.equipamento_id) &&
        !Boolean(user?.permissoes?.block_descarte_equipamento) &&
        [19]?.includes(row?.status_id),
      action: ({ row }) =>
        changeStatus({ documento_id: row?.id, status_id: 27 }),
    },
    {
      name: 'Devolver Equipamento',
      icon: 'redo',
      menu: true,
      show: ({ row }) =>
        Boolean(row?.equipamento_id) &&
        !Boolean(user?.permissoes?.block_devolucao_equipamento) &&
        [19]?.includes(row?.status_id),
      action: ({ row }) => {
        if (!Boolean(row?.localizacao)) {
          toastWarning('Localização do equipamento não informada');
        } else {
          changeStatus({ documento_id: row?.id, status_id: 26 });
        }
      },
    },
    {
      name: 'Entregar Equipamento',
      icon: 'check',
      menu: true,
      show: ({ row }) =>
        Boolean(row?.equipamento_id) &&
        !Boolean(user?.permissoes?.block_entrega_equipamento) &&
        [26]?.includes(row?.status_id),
      action: ({ row }) =>
        changeStatus({
          documento_id: row?.id,
          status_id: 6,
          motivo: 'Equipamento Entregue',
        }),
    },
  ];

  return (
    <Container>
      <Header rotina={rotina} titulo={titulo} colunas={orçamentos?.colunas} />
      <Card>
        <Grid
          grid={orçamentos}
          mock={mock}
          options={options}
          rowOptions={rowOptions}
          control={control}
          checkboxSelection
          loading={loading}
          loadGrid={loadGrid}
          getRowHeight={() => 'auto'}
          activeFilter={(getActiveFilter) => getActiveFilter(getValues())}
          FilterComponent={
            <Filter grid={orçamentos} control={control} drops={drops} />
          }
        />
      </Card>
    </Container>
  );
};

export default Orçamentos;
