import React, { useContext, useEffect, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { Grid, LinearProgress, Tab, Tabs } from '@mui/material';
import { LocationOn, MiscellaneousServices } from '@mui/icons-material';
import { decryptURL } from 'utils/functions';
import { AuthContext } from 'contexts/AuthContext';
import { GridContext } from 'contexts/GridContext';
import { DropsContext } from 'contexts/DropsContext';
import { DocumentosContext } from 'contexts/DocumentosContext';
import { useModal } from 'components/Modals';
import DeleteModal from 'components/Modals/DeleteModal';
import Container from 'components/Container';
import Header from 'components/Header';
import Loader from 'components/Loader';
import Card from 'components/Card';
import styles from './styles';
import CustomTabs from './Tabs';
import localeText from 'utils/localeText';

const DocumentoEquipamento = () => {
  const navigate = useNavigate();
  const [tab, setTab] = useState(0);
  const { hash } = useParams();
  const { openModal, closeModal } = useModal();
  const { user } = useContext(AuthContext);
  const { drops } = useContext(DropsContext);
  const { deleteGrid } = useContext(GridContext);
  const { documento, getLoading, postLoading, getDocumento, postDocumento } =
    useContext(DocumentosContext);
  const decrypt = decryptURL(hash);
  const equipamento = documento?.DocumentoEquipamentos?.find(
    (f) => f?.id === decrypt?.id
  );
  const disableActions =
    Boolean(documento?.DocumentoHists?.length) &&
    documento?.DocumentoHists?.find((f) => Boolean(f?.status_id))?.Statusdc
      ?.editar === 'NAO';
  const gridProps = {
    hideFooter: true,
    autoHeight: true,
    density: 'compact',
    disableSelectionOnClick: true,
    disableColumnMenu: true,
    showCellRightBorder: true,
    showColumnRightBorder: true,
    getRowHeight: () => 'auto',
    loading: postLoading,
    localeText,
    components: { LoadingOverlay: LinearProgress },
  };

  const loadDocumento = () => getDocumento(decrypt?.documento_id);

  useEffect(() => {
    if (!Boolean(decrypt) || !Boolean(equipamento)) {
      navigate(-1);
    }
  }, []);

  const onSubmit = (data) =>
    postDocumento({
      data,
      cb: () => {
        loadDocumento();
        closeModal();
      },
    });

  const onDelete = (params) =>
    openModal(
      <DeleteModal
        onSubmit={() =>
          deleteGrid({
            params,
            cb: () => {
              loadDocumento();
              closeModal();
            },
          })
        }
      />
    );

  const tabs = [
    {
      value: 0,
      key: 'Defeitos',
      label: 'Defeitos',
      icon: <MiscellaneousServices />,
    },
    {
      value: 1,
      key: 'Locais',
      label: 'Locais',
      icon: <LocationOn />,
    },
  ];

  if (getLoading) {
    return <Loader />;
  }

  return (
    <Container>
      <Header titulo={equipamento?.Equipamento?.descricao || ''} />
      <Grid container spacing={2}>
        <Grid item xs={12}>
          <Card>
            <Tabs
              variant="fullWidth"
              value={tab}
              onChange={(_, tab) => setTab(tab)}
              TabIndicatorProps={styles?.indicatorTabs}
              sx={styles?.tabs}
            >
              {tabs?.map(
                ({ show = true, key, ...t }) => show && <Tab key={key} {...t} />
              )}
            </Tabs>
          </Card>
        </Grid>
        <Grid item xs={12}>
          <CustomTabs
            tab={tab}
            user={user}
            drops={drops}
            onSubmit={onSubmit}
            onDelete={onDelete}
            loading={postLoading}
            gridProps={gridProps}
            getLoading={getLoading}
            equipamento={equipamento}
            disableActions={disableActions}
          />
        </Grid>
      </Grid>
    </Container>
  );
};

export default DocumentoEquipamento;
