import React, { useContext, useEffect, useState } from 'react';
import { useNavigate, useOutletContext, useParams } from 'react-router-dom';
import { Grid, LinearProgress, Tab, Tabs } from '@mui/material';
import {
  AllInbox,
  Article,
  AttachMoney,
  Ballot,
  Construction,
  Description,
  LocalShipping,
  Mail,
  Schedule,
  Send,
} from '@mui/icons-material';
import { encryptURL } from 'utils/functions';
import { AuthContext } from 'contexts/AuthContext';
import { GridContext } from 'contexts/GridContext';
// import { NotasContext } from 'contexts/NotasContext';
import { DropsContext } from 'contexts/DropsContext';
import { VendaContext } from 'contexts/VendaContext';
import { DocumentosContext } from 'contexts/DocumentosContext';
import { RelatoriosContext } from 'contexts/RelatoriosContext';
import { useModal, useDialog } from 'components/Modals';
import JustificativaModal from 'components/Modals/JustificativaModal';
import DeleteModal from 'components/Modals/DeleteModal';
import Container from 'components/Container';
import Header from 'components/Header';
import Loader from 'components/Loader';
import Card from 'components/Card';
import styles from './styles';
import CustomTabs from './Tabs';
import localeText from 'utils/localeText';
import mock from './mock';

const Documento = () => {
  const navigate = useNavigate();
  const [selected, setSelected] = useState([]);
  const { onChangeTab, tab } = useOutletContext();
  const { id } = useParams();
  const { openModal, closeModal } = useModal();
  const { openDialog, closeDialog } = useDialog();
  const { user } = useContext(AuthContext);
  const { drops } = useContext(DropsContext);
  const { contas } = useContext(VendaContext);
  const { deleteGrid } = useContext(GridContext);
  // const { getConsultaNota } = useContext(NotasContext);
  const { getURLRelatorio, getURLEmissao } = useContext(RelatoriosContext);
  const {
    documento,
    postAnexo,
    getLoading,
    postLoading,
    deleteAnexo,
    getDocumento,
    postDocumento,
    postDocumentos,
    deleteDocumento,
    postEncerramento,
    postDocumentoItemCfo,
    postDocumentoItemImposto,
  } = useContext(DocumentosContext);
  const { isSaida } = mock({ documento });
  const disableActions =
    Boolean(documento?.DocumentoHists?.length) &&
    documento?.DocumentoHists?.find((f) => Boolean(f?.status_id))?.Statusdc
      ?.editar === 'NAO';
  const status_id = documento?.DocumentoHists?.find((f) =>
    Boolean(f?.status_id)
  )?.status_id;
  const aprv_pendente = documento?.DocumentoItems?.some((d) =>
    d?.DocumentoItemAprovacaos?.some((a) => !Boolean(a?.aprovado))
  );
  const gridProps = {
    hideFooter: true,
    autoHeight: true,
    density: 'compact',
    disableSelectionOnClick: true,
    disableColumnMenu: true,
    showCellRightBorder: true,
    showColumnRightBorder: true,
    getRowHeight: () => 'auto',
    loading: postLoading,
    localeText,
    components: { LoadingOverlay: LinearProgress },
  };

  const loadDocumento = () => getDocumento(id);

  const getTitulo = () => {
    let titulo = '';
    const numero = documento?.documento || '';
    const especie = documento?.Especie?.descricao || '';
    const natureza = documento?.NaturezaOperacao?.natureza || '';
    if (Boolean(especie)) {
      titulo += especie;
    }
    // if (Boolean(natureza)) {
    //   titulo += ` DE ${natureza}`;
    // }
    if (Boolean(numero)) {
      titulo += `: ${numero}`;
    }
    return titulo?.toUpperCase();
  };

  const getSubtitulo = () => {
    const situacao = documento?.situacao || '';
    if (Boolean(situacao)) {
      return situacao?.toUpperCase();
    }
    return null;
  };

  useEffect(() => {
    loadDocumento();
    onChangeTab({ tab, key: id });
  }, []);

  const onSubmit = (data) =>
    postDocumento({
      data,
      cb: () => {
        loadDocumento();
        closeDialog();
        closeModal();
      },
    });

  const onSubmitImposto = () =>
    postDocumentoItemImposto({
      data: selected.map((s) => s?.id),
      cb: loadDocumento,
    });

  const onSubmitCfo = () =>
    postDocumentoItemCfo({
      data: selected.map((s) => s?.id),
      cb: loadDocumento,
    });

  const onSubmitAnexo = (data) =>
    postAnexo({
      data,
      cb: () => {
        loadDocumento();
        closeDialog();
      },
    });

  const onDeleteAnexo = (id) =>
    openModal(
      <DeleteModal
        onSubmit={() =>
          deleteAnexo({
            id,
            cb: () => {
              loadDocumento();
              closeModal();
            },
          })
        }
      />
    );

  const onDelete = (params) =>
    openModal(
      <DeleteModal
        onSubmit={() =>
          deleteGrid({
            params,
            cb: () => {
              loadDocumento();
              closeModal();
            },
          })
        }
      />
    );

  const onDuplicate = () => {
    const refactor = (obj = {}) => {
      if (Boolean(obj)) {
        let ret = {};
        Object.keys(obj)
          ?.filter((f) => f !== 'id' && f !== 'documento_id')
          ?.map((key) => {
            if (key?.charAt(0) !== key?.charAt(0)?.toUpperCase()) {
              ret[key] = obj[key];
            }
          });
        return ret;
      } else {
        return null;
      }
    };
    const Itens = documento?.DocumentoItems?.map((d) => ({
      ...refactor(d),
      Margem: refactor(d?.DocumentoItemMc),
      Impostos: d?.DocumentoItemImpostos?.map((dd) => refactor(dd)),
      Variacoes: d?.DocumentoItemVariacaos?.map((dd) => refactor(dd)),
      Processos: d?.DocumentoItemProcessos?.map((dd) => refactor(dd)),
      Estruturas: d?.DocumentoItemEstruturas?.map((dd) => refactor(dd)),
      Aprovacoes: d?.DocumentoItemAprovacaos?.map((dd) => refactor(dd)),
      Qualidades: d?.DocumentoItemQualidades?.map((dd) => refactor(dd)),
    }));
    const data = {
      Itens,
      Documento: refactor(documento),
      NFe: refactor(documento?.DocumentoNfe),
      MDFe: refactor(documento?.DocumentoMdfe),
      Moeda: refactor(documento?.DocumentoMoeda),
      Saida: refactor(documento?.DocumentoSaida),
      PedidoCompra: refactor(documento?.Copedido),
      CotacaoCompra: refactor(documento?.Cocotacao),
      Entrada: refactor(documento?.DocumentoEntrada),
      Servico: refactor(documento?.DocumentoServico),
      Contrato: refactor(documento?.DocumentoContrato),
      RequisicaoCompra: refactor(documento?.Corequisicao),
      Importacao: refactor(documento?.DocumentoImportacao),
      Exportacao: refactor(documento?.DocumentoExportacao),
      Cces: documento?.DocumentoCces?.map((d) => refactor(d)),
      Registros: documento?.DocumentoHists?.map((d) => refactor(d)),
      Transportes: documento?.DocumentoTransportes?.map((d) => refactor(d)),
      Financeiros: documento?.DocumentoFinanceiros?.map((d) => refactor(d)),
      Equipamentos: documento?.DocumentoEquipamentos?.map((d) => refactor(d)),
      NFeServico: documento?.DocumentoNfeServicos?.map((d) => refactor(d)),
    };
    for (const key in data) {
      if (!Boolean(data[key])) {
        delete data[key];
      }
      if (Array.isArray(data[key]) && !Boolean(data[key]?.length)) {
        delete data[key];
      }
    }
    postDocumento({ data, cb: () => navigate(-1) });
  };

  const onChangeStatus = (status_id) =>
    onSubmit({ Registros: [{ documento_id: id, status_id }] });

  const regIndispPeca = () => {
    const orgs = documento?.DocumentoItems?.map(
      (di) => di?.DocumentoItemOrigems[0]
    )?.map((dio) => dio?.documento_id_origem);

    const data = [
      {
        Registros: [
          {
            documento_id: documento?.id,
            status_id: 2,
            motivo: 'PEÇA INDISPONÍVEL',
          },
        ],
      },
    ];

    [...new Set(orgs)]?.map((documento_id) =>
      data?.push({
        Registros: [{ documento_id, status_id: 28 }],
      })
    );
    postDocumentos({ data, cb: loadDocumento });
  };

  const tabs = [
    {
      value: 0,
      key: 'Documento',
      label: 'Documento',
      icon: <Description />,
    },
    {
      value: 1,
      key: 'Itens',
      label: 'Itens',
      icon: <Ballot />,
    },
    {
      value: 2,
      key: 'Equipamentos',
      label: 'Equipamentos',
      icon: <Construction />,
      show: documento?.NaturezaOperacao?.natureza === 'SERVICOS',
    },
    {
      value: 3,
      key: 'Financeiro',
      label: 'Financeiro',
      icon: <AttachMoney />,
      show: documento?.NaturezaOperacao?.financeiro === 'SIM',
    },
    {
      value: 4,
      key: 'Transportes',
      label: 'Transportes',
      icon: <LocalShipping />,
      show: documento?.Especie?.transporte === 'SIM',
    },
    {
      value: 5,
      key: 'MDFE',
      label: 'MDFE',
      icon: <AllInbox />,
      show: Boolean(documento) && Boolean(documento?.DocumentoMdfe),
    },
    {
      value: 6,
      key: 'Registros',
      label: 'Registros',
      icon: <Article />,
    },
    {
      value: 7,
      key: 'Forma de Envio',
      label: 'Forma de Envio',
      icon: <Send />,
      show: isSaida && documento?.Especie?.transporte === 'SIM',
    },
    {
      value: 8,
      key: 'Status da Transmissão',
      label: 'Status da Transmissão',
      icon: <Schedule />,
      show: Boolean(documento?.DocumentoNfeServicos?.length),
    },
    {
      value: 9,
      key: 'Carta de Correção',
      label: 'Carta de Correção',
      icon: <Mail />,
      show: documento?.Especie?.modelofiscal_id === 31,
    },
  ];

  const options = [
    {
      name: 'Cancelar Documento',
      icon: 'cancel',
      show:
        Boolean(documento) &&
        (documento?.DocumentoHists?.every((e) => !Boolean(e?.status_id)) ||
          documento?.DocumentoHists?.find((f) => Boolean(f?.status_id))
            ?.Statusdc?.cancelar === 'SIM'),
      action: () =>
        openModal(
          <JustificativaModal
            onSubmit={({ motivo }) =>
              onSubmit({
                Documento: { id, conferido: 'NAO' },
                Registros: [{ documento_id: id, status_id: 2, motivo }],
              })
            }
          />
        ),
    },
    {
      name: 'Duplicar Documento',
      icon: 'file_copy',
      show: Boolean(documento),
      action: onDuplicate,
    },
    {
      name: 'Excluir Documento',
      icon: 'delete',
      show:
        Boolean(documento) &&
        (documento?.DocumentoHists?.every((e) => !Boolean(e?.status_id)) ||
          documento?.DocumentoHists?.find((f) => Boolean(f?.status_id))
            ?.Statusdc?.excluir === 'SIM'),
      action: () => deleteDocumento({ id, cb: () => navigate(-1) }),
    },
    {
      name: 'Desregistrar Documento',
      icon: 'app_registration',
      show:
        Boolean(documento) &&
        documento?.conferido === 'SIM' &&
        documento?.Especie?.registrar === 'SIM',
      action: () => onSubmit({ Documento: { id, conferido: 'NAO' } }),
    },
    {
      name: 'Concluir Documento',
      icon: 'check',
      show:
        Boolean(documento) &&
        status_id !== 6 &&
        documento?.situacao === 'PENDENTE' &&
        documento?.Especie?.registrar === 'NAO',
      action: () => onChangeStatus(6),
    },
    {
      name: 'Reabrir Documento',
      icon: 'lock_open',
      show:
        Boolean(documento) &&
        status_id !== 1 &&
        documento?.situacao === 'CONCLUIDO' &&
        documento?.Especie?.registrar === 'NAO',
      action: () => onChangeStatus(1),
    },
  ];

  if (getLoading) {
    return <Loader />;
  }

  return (
    <Container>
      <Header
        titulo={getTitulo()}
        subtitulo={getSubtitulo()}
        outros={options}
      />
      <Grid container spacing={2}>
        <Grid item xs={12}>
          <Card>
            <Tabs
              variant="fullWidth"
              value={tab}
              onChange={(_, tab) => onChangeTab({ tab, key: id })}
              TabIndicatorProps={styles?.indicatorTabs}
              sx={styles?.tabs}
            >
              {tabs?.map(
                ({ show = true, key, ...t }) => show && <Tab key={key} {...t} />
              )}
            </Tabs>
          </Card>
        </Grid>
        <Grid item xs={12}>
          <CustomTabs
            tab={tab}
            user={user}
            drops={drops}
            onSubmit={onSubmit}
            onDelete={onDelete}
            selected={selected}
            loading={postLoading}
            documento={documento}
            gridProps={gridProps}
            getLoading={getLoading}
            onSubmitCfo={onSubmitCfo}
            setSelected={setSelected}
            onSubmitAnexo={onSubmitAnexo}
            onDeleteAnexo={onDeleteAnexo}
            disableActions={disableActions}
            getURLRelatorio={getURLRelatorio}
            onSubmitImposto={onSubmitImposto}
          />
        </Grid>
      </Grid>
    </Container>
  );
};

export default Documento;
