import React, { useContext, useEffect, useState } from 'react';
import { DataGrid, GridActionsCellItem } from '@mui/x-data-grid';
import {
  Box,
  DialogActions,
  DialogContent,
  DialogTitle,
  Grid,
  Icon,
} from '@mui/material';
import { useParams } from 'react-router-dom';
import { useModal } from 'components/Modals';
import { useForm } from 'react-hook-form';
import { GridContext } from 'contexts/GridContext';
import { DropsContext } from 'contexts/DropsContext';
import { EquipamentosContext } from 'contexts/EquipamentosContext';
import { findOnArray } from 'utils/functions';
import Container from 'components/Container';
import Header from 'components/Header';
import Button from 'components/Button';
import VirtualDrop from 'components/VirtualDrop';
import Card from 'components/Card';
import DeleteModal from 'components/Modals/DeleteModal';
import localeText from 'utils/localeText';

const Modal = ({ onSubmit }) => {
  const defaultValues = { relacionado_id: null, ativo: 'SIM' };
  const { control, handleSubmit } = useForm({ defaultValues });
  const { drops } = useContext(DropsContext);
  const { closeModal } = useModal();
  const { postLoading } = useContext(GridContext);

  return (
    <>
      <DialogTitle>Adicionar Produto Relacionado</DialogTitle>
      <DialogContent>
        <Grid container spacing={2}>
          <Grid item xs={12} />
          <Grid item xs={12}>
            <VirtualDrop
              name="relacionado_id"
              control={control}
              label="Produto"
              options={drops?.Produto}
            />
          </Grid>
        </Grid>
      </DialogContent>
      <DialogActions>
        <Button
          variant="contained"
          onClick={handleSubmit(onSubmit)}
          loading={postLoading}
        >
          Salvar
        </Button>
        <Button onClick={closeModal} color="secondary" variant="contained">
          Cancelar
        </Button>
      </DialogActions>
    </>
  );
};

const Relacionados = () => {
  const { id } = useParams();
  const [equipamentos, setEquipamentos] = useState([]);
  const { openModal, closeModal } = useModal();
  const rotina = 'ProdutoRelacionado';
  const { drops } = useContext(DropsContext);
  const { getRelacionados, getLoading } = useContext(EquipamentosContext);
  const { postGrid, postLoading, deleteGrid, deleteLoading } =
    useContext(GridContext);
  const loading = postLoading || getLoading || deleteLoading;
  const equipamento = findOnArray(Number(id), drops?.Equipamento, 'label');

  const loadData = () => {
    getRelacionados({ params: { id }, cb: setEquipamentos });
    closeModal();
  };

  const onSubmit = (values) => {
    const data = { ...values, equipamento_id: id };
    postGrid({ data, rotina, cb: loadData });
  };

  useEffect(() => {
    loadData();
  }, []);

  return (
    <Container>
      <Header titulo="Produtos Relacionados" subtitulo={equipamento} />
      <Card>
        <Box sx={{ gap: 2, display: 'flex', flexDirection: 'column' }}>
          <Button
            variant="outlined"
            color="primary"
            sx={{ alignSelf: 'center' }}
            onClick={() => openModal(<Modal onSubmit={onSubmit} />)}
            loading={loading}
          >
            Adicionar
          </Button>
          <DataGrid
            rows={equipamentos}
            columns={[
              {
                field: 'actions',
                headerName: 'Ações',
                type: 'actions',
                width: 100,
                getActions: ({ row }) => [
                  <GridActionsCellItem
                    icon={<Icon>delete</Icon>}
                    label="Excluir"
                    onClick={() =>
                      openModal(
                        <DeleteModal
                          onSubmit={() =>
                            deleteGrid({
                              params: { rotina, id: row?.id },
                              cb: loadData,
                            })
                          }
                        />
                      )
                    }
                  />,
                  <GridActionsCellItem
                    icon={
                      <Icon>{row?.ativo === 'SIM' ? 'key_off' : 'key'}</Icon>
                    }
                    label={row?.ativo === 'SIM' ? 'Inativar' : 'Ativar'}
                    onClick={() =>
                      onSubmit({
                        ...row,
                        ativo: row?.ativo === 'SIM' ? 'NAO' : 'SIM',
                      })
                    }
                  />,
                ],
              },
              {
                field: 'referencia',
                headerName: 'Referência',
                width: 200,
                sortable: false,
              },
              {
                field: 'descricao',
                headerName: 'Descrição',
                flex: 1,
                sortable: false,
              },
              {
                field: 'ativo',
                headerName: 'Ativo',
                width: 100,
                sortable: false,
              },
            ]}
            hideFooter
            autoHeight
            density="compact"
            disableSelectionOnClick
            disableColumnMenu
            showCellRightBorder
            showColumnRightBorder
            localeText={localeText}
            loading={loading}
          />
        </Box>
      </Card>
    </Container>
  );
};

export default Relacionados;
